import React, { Component } from "react";
import headerImage from "../static/images/MidwinterPhysiotherapy-059-Edit.webp";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <link
          rel="preload"
          fetchpriority="highest"
          as="image"
          href={headerImage}
        />
        <header>
          <div className="social">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/midwinterphysio"
                  title="Facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/midwinterphysio"
                  title="Twitter"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/midwinterphysio/"
                  title="LinkedIn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a
                  href="http://instagram.com/midwinterphysio"
                  title="Instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://api.whatsapp.com/send?phone=447723541664"
                  title="WhatsApp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-whatsapp"></i>
                </a>
              </li>
            </ul>
          </div>
          <nav>
            <a href="/">Home</a>
            <a href="/about">About</a>
            <a href="/careers">Careers</a>
          </nav>
          <a href="/">
            <img
              src="/images/logo.png"
              fetchpriority="high"
              style={{ width: "64px", height: "64px", marginTop: "100px" }}
              alt="Midwinter Physiotherapy"
            />
          </a>
          <h1>
            <span className="midwinter">Midwinter</span>
            <br />
            Physiotherapy
          </h1>

          <ul>
            <li>For all your rehabilitation needs</li>
            <li>Clinic and home visits</li>
            <li>
              Based at{" "}
              <a
                href="https://www.google.com/maps/place/Midwinter+Physiotherapy/@52.085682,-1.9634272,15z/data=!4m6!3m5!1s0x4870df02fbbabd09:0x2f4ae5a774dd8d9a!8m2!3d52.088319!4d-1.9545437!16s%2Fg%2F1td32h4d?entry=ttu"
                target="_blank"
                rel="noreferrer"
              >
                Evesham Leisure Centre
              </a>
            </li>
            <li>We cover South Worcestershire and beyond</li>
          </ul>
        </header>
        <section
          style={{
            textAlign: "center",
            borderBottom: "1px dotted #9259e6",
          }}
        >
          <a href="tel:01386240189">01386 240189</a>
          {" · "}
          <a href="mailto:info@midwinterphysiotherapy.co.uk">email</a>
          {" · "}
          <a
            href="https://api.whatsapp.com/send?phone=447723541664"
            target="_blank"
            rel="noreferrer"
          >
            whatsapp
          </a>
          {" · "}
          <a
            href="https://midwinter-physiotherapy.uk1.cliniko.com/bookings?business_id=572971946795341686&practitioner_id=1228283810966148859&appointment_type_id=677195526994662681"
            target="_blank"
            rel="noreferrer"
          >
            book online
          </a>
        </section>
      </>
    );
  }
}

export default Header;
