import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "About our Physiotherapy practice";
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          <section>
            <p>
              We pride ourselves on providing bespoke, excellent physiotherapy
              in your home or our Evesham clinics. We work with you as a team to
              develop a patient centred rehabilitation package and advocate for
              your healthcare needs with your GP, consultant and other
              professionals as required. We aim to reduce symptom burden using
              evidence based treatments in a cost effective way.
            </p>
            <p>
              All our physiotherapists are chartered and members of the
              Chartered Society for Physiotherapy (CSP). We are all registered
              and governed by the Health Care and Professions Council (HCPC) and
              work within this remit to provide safe and effective care. Our
              support workers are also CSP members and trained to deliver
              particular aspects of rehabilitation to a high standard.
            </p>
            <p>
              We also work with other providers such as Herefordshire &
              Worcestershire CCG, Worcester University,{" "}
              <a
                href="https://myriadcentre.co.uk/hydrotherapy/"
                target="_blank"
                rel="noreferrer"
              >
                The Myriad Centre
              </a>{" "}
              and{" "}
              <a
                href="https://www.spectrumdays.co.uk/"
                target="_blank"
                rel="noreferrer"
              >
                Spectrum Days
              </a>{" "}
              providing specialist physiotherapy.
            </p>
          </section>
          <section>
            <h2>The Physiotherapy Team</h2>

            <div className="profile">
              <div>
                <img
                  src="/images/shoot/MidwinterPhysiotherapy-021-Edit.webp"
                  alt="Siân"
                />
              </div>
              <div className="text">
                <div className="title">Siân Midwinter MSc MCSP</div>
                <div className="description">
                  Chartered Physiotherapist & Founder
                  <br />
                  Specialist Neuro & Respiratory
                </div>
              </div>
            </div>

            <div className="profile">
              <div>
                <img
                  src="/images/shoot/MidwinterPhysiotherapy-007-Edit.webp"
                  alt="Rohin"
                />
              </div>
              <div className="text">
                <div className="title">Rohin Sunner MSc MCSP</div>
                <div className="description">
                  Chartered Physiotherapist
                  <br />
                  Musculoskeletal
                </div>
              </div>
            </div>

            <div className="profile">
              <div>
                <img src="/images/shoot/Laura head shot.webp" alt="Laura" />
              </div>
              <div className="text">
                <div className="title">Laura Aitken PGDip BSc MCSP</div>
                <div className="description">
                  Chartered Physiotherapist
                  <br />
                  Musculoskeletal & Neurological Rehabilitation
                </div>
              </div>
            </div>

            <div className="profile">
              <div>
                <img
                  src="/images/shoot/MidwinterPhysiotherapy-022-Edit.webp"
                  alt="Stephanie"
                />
              </div>
              <div className="text">
                <div className="title">Stephanie Cooke BSc MCSP</div>
                <div className="description">
                  Chartered Physiotherapist
                  <br />
                  Specialist Respiratory
                </div>
              </div>
            </div>
          </section>
          <section>
            <h2>The Support Team</h2>

            <div className="profile">
              <div>
                <img
                  src="/images/shoot/MidwinterPhysiotherapy-031-Edit.webp"
                  alt="Sian"
                />
              </div>
              <div className="text">
                <div className="title">Kerry Wood BA</div>
                <div className="description">Physiotherapy Assistant</div>
              </div>
            </div>

            <div className="profile">
              <div>
                <img src="/images/shoot/Lucy headshot.webp" alt="Sian" />
              </div>
              <div className="text">
                <div className="title">Lucy Austin</div>
                <div className="description">Administrative Support</div>
              </div>
            </div>
          </section>
          <section>
            <h2>Feedback</h2>

            <a
              href="https://g.co/kgs/YkmifG"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="google-review">
                <p>
                  Sian and staff have been the reason that I am now nearly
                  walking unaided. They have helped, encouraged and been there
                  with me on my long journey from near paralysis to full
                  movement in my legs. Everyone is continually smiling which
                  lightens your mood so the exercises seem much easier than they
                  are. Thank you Sian and Kerry
                </p>
              </div>
            </a>

            <a
              href="https://g.co/kgs/PjsfG6"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="google-review">
                <p>
                  Sian is a very experienced, professional and above all
                  empathetic physiotherapist. Not only does she have a wealth of
                  physiotherapy knowledge but she understands a plethora of
                  other medical conditions that allow her to treat individual
                  patients holistically. Sian is passionate in what she does and
                  is always positive and happy. Sian is a credit to her
                  profession and I could not rate her highly enough .
                </p>
              </div>
            </a>

            <a
              href="https://www.google.com/maps/place/Midwinter+Physiotherapy/@52.088319,-1.9545437,17z/data=!4m8!3m7!1s0x4870df02fbbabd09:0x2f4ae5a774dd8d9a!8m2!3d52.088319!4d-1.9545437!9m1!1b1!16s%2Fg%2F1td32h4d?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Read more reviews »
            </a>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default About;
