import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer>
        <a href="/">
          <img
            src="/images/logo_white.png"
            style={{ width: "48px", height: "48px" }}
            alt="Midwinter Physiotherapy"
          />
        </a>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridGap: "10px",
            marginTop: "20px",
          }}
        >
          <div>
            <a
              href="https://www.google.com/maps/place/Midwinter+Physiotherapy/@52.085682,-1.9634272,15z/data=!4m6!3m5!1s0x4870df02fbbabd09:0x2f4ae5a774dd8d9a!8m2!3d52.088319!4d-1.9545437!16s%2Fg%2F1td32h4d?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              Evesham Leisure Centre
            </a>
            <br />
            Abbey Road
            <br />
            Evesham WR11 4SU
            <br />
            <br />
            <a href="/privacy">Privacy Policy</a>
          </div>
          <div>
            <a href="tel:01386240189">01386 240189</a>
            {" · "}
            <a
              href="https://api.whatsapp.com/send?phone=447723541664"
              target="_blank"
              rel="noreferrer"
            >
              whatsapp
            </a>
            <br />
            <a href="mailto:info@midwinterphysiotherapy.co.uk">
              info@midwinterphysiotherapy.co.uk
            </a>
            <br />
            <a
              href="https://midwinter-physiotherapy.uk1.cliniko.com/bookings?business_id=572971946795341686&practitioner_id=1228283810966148859&appointment_type_id=677195526994662681"
              target="_blank"
              rel="noreferrer"
            >
              book online
            </a>
            <br />
            <br />
            &copy;2024{" "}
            <a href="https://midwinterphysiotherapy.co.uk">
              Midwinter Physiotherapy
            </a>
          </div>
        </div>
        <div className="social">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/midwinterphysio"
                title="Facebook"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/midwinterphysio"
                title="Twitter"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/midwinterphysio/"
                title="LinkedIn"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a
                href="http://instagram.com/midwinterphysio"
                title="Instagram"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=447723541664"
                title="WhatsApp"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-whatsapp"></i>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}

export default Footer;
