import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

class Careers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOnly: true,
    };
  }

  componentDidMount() {
    document.title = "Physiotherapy Careers";
  }

  toggleOpen() {
    this.setState({ openOnly: !this.state.openOnly });
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          {/* <section>
            <label for="open">
              <input
                name="open"
                type="checkbox"
                checked={this.state.openOnly}
                onChange={() => this.toggleOpen()}
              />
              &nbsp; Open positions only?
            </label>
          </section> */}
          <section>
            <i className="tag open">open</i>
            <h2>Physiotherapist</h2>
            <p className="tagline">
              Community and Learning Disability Physiotherapist
            </p>
            <p>
              Are you looking for a new challenge and want to work in a
              thriving, growing private physiotherapy practice that's different
              to all the others? Do you want to really make a difference and
              transform peoples lives and love working with adults with learning
              disabilities and older adults in the community? If Yes then keep
              reading;
            </p>
            <p>
              We are looking for a full time physiotherapist with experience in
              neurology, older adults and learning disabilities. We want someone
              who is willing to go over and above for the clients and their
              families. This is an exciting, multifaceted role and perfect if
              you have a passion for community and neurological physiotherapy.
              This role is suitable for someone who is driven, enjoys the
              opportunity to step outside of their comfort zone, and loves a
              challenge.
            </p>
            <p>
              Essential: Qualification as a Chartered Physiotherapist (CSP),
              Health Professions Council registration (HCPC). (Pending or
              Received). Willingness to learn quickly. Flexibility in both work
              schedules and holiday cover. Good presentation and serious
              interest in your own health and wellbeing. Excellent time
              management skills. Excellent interpersonal communication skills,
              including presentation skills. Computer literacy. Full Driving
              License.
            </p>
            <p>
              If you're interested send us a 150 word email about why you'd be
              perfect for the role to{" "}
              <a href="mailto:sian@midwinterphysiotherapy.co.uk">Sian</a>
              &nbsp;by 24th June 2024.
            </p>
          </section>
          {/* {!this.state.openOnly && (
            <section>
              <i className="tag">closed</i>
              <h2>Physiotherapy Assistant</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                pulvinar at justo eu finibus. Quisque fringilla vulputate leo
                sit amet cursus. Suspendisse aliquam velit in orci eleifend
                gravida. Donec lobortis molestie orci in tempor. Pellentesque
                lacinia aliquet justo. Nulla eu tristique lectus, in egestas
                sapien. Praesent id leo enim. Duis sit amet ultrices ipsum,
                efficitur ullamcorper nulla. Duis ut felis ut dui dapibus
                porttitor id vitae nunc. Vestibulum blandit eros nec enim
                fringilla, quis bibendum tortor pulvinar. Aenean imperdiet mi
                turpis, lacinia sollicitudin dui dignissim et. Etiam et orci
                molestie, interdum metus non, maximus neque.
              </p>
            </section>
          )}

          {!this.state.openOnly && (
            <section>
              <i className="tag">closed</i>
              <h2>Office Manager</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                pulvinar at justo eu finibus. Quisque fringilla vulputate leo
                sit amet cursus. Suspendisse aliquam velit in orci eleifend
                gravida. Donec lobortis molestie orci in tempor. Pellentesque
                lacinia aliquet justo. Nulla eu tristique lectus, in egestas
                sapien. Praesent id leo enim. Duis sit amet ultrices ipsum,
                efficitur ullamcorper nulla. Duis ut felis ut dui dapibus
                porttitor id vitae nunc. Vestibulum blandit eros nec enim
                fringilla, quis bibendum tortor pulvinar. Aenean imperdiet mi
                turpis, lacinia sollicitudin dui dignissim et. Etiam et orci
                molestie, interdum metus non, maximus neque.
              </p>
            </section>
          )} */}
        </div>

        <Footer />
      </>
    );
  }
}

export default Careers;
