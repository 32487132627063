import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Header />

        <div id="sections">
          <section>
            <h2>Neurological Rehabilitation</h2>

            <div className="cards">
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-440.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "bottom",
                }}
              ></div>
              <div className="card">
                <ul>
                  <li>Stroke</li>
                  <li>Parkinsons Disease</li>
                  <li>Traumatic Brain Injuries</li>
                  <li>Spinal Cord Injury</li>
                  <li>Dementia</li>
                </ul>
              </div>
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-142-Enhanced-NR.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          </section>

          <section>
            <h2>Respiratory Physiotherapy</h2>

            <div className="cards">
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-306.webp')",
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="card">
                <ul>
                  <li>Breathing Pattern Disorders</li>
                  <li>Chest Clearance</li>
                  <li>Breathlessness</li>
                  <li>Recurrent Infections</li>
                </ul>
              </div>
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-352-Enhanced-NR.webp')",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            <div className="cards" style={{ marginTop: "10px" }}>
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-448.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="card">
                <ul>
                  <li>Pulmonary Rehabilitation</li>
                  <li>Long Covid</li>
                  <li>POTS (Postural Orthostatic Tachycardia Syndrome)</li>
                </ul>
              </div>
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-300.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          </section>

          <section>
            <h2>Musculoskeletal Physiotherapy</h2>

            <div className="cards">
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-239-Enhanced-NR.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="card">
                <ul>
                  <li>Injuries</li>
                  <li>Chronic Pain</li>
                  <li>Pre/Post Operative</li>
                  <li>Arthritis</li>
                  <li>Mobility Problems</li>
                  <li>
                    <a
                      href="https://midwinter-physiotherapy.uk1.cliniko.com/bookings?business_id=572971946795341686&practitioner_id=1228283810966148859&appointment_type_id=677195526994662681"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i>Book online*</i>
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-088-Enhanced-NR.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
            <p style={{ marginTop: "10px", fontSize: "1rem" }}>
              * You can self refer into our musculoskeletal clinic by{" "}
              <a
                href="https://midwinter-physiotherapy.uk1.cliniko.com/bookings?business_id=572971946795341686&practitioner_id=1228283810966148859&appointment_type_id=677195526994662681"
                target="_blank"
                rel="noreferrer"
              >
                booking online
              </a>
            </p>
          </section>

          <section>
            <h2>Aquatic & Hydrotherapy</h2>

            <div className="cards">
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-388-Enhanced-NR.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="card">
                <ul>
                  <li>Reduced Mobility</li>
                  <li>Learning Disabilities</li>
                  <li>Post Operative</li>
                  <li>Pain</li>
                </ul>
              </div>
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-399.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          </section>

          <section>
            <h2>Elderly Rehab & Home Visits</h2>

            <div className="cards">
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-160-Enhanced-NR.webp')",
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="card">
                <ul>
                  <li>Reduced Mobility</li>
                  <li>Falls Prevention</li>
                  <li>Care Homes</li>
                  <li>Dementia</li>
                  <li>Reverse Osteoporosis</li>
                </ul>
              </div>
              <div
                className="card"
                style={{
                  background:
                    "url('/images/shoot/MidwinterPhysiotherapy-365-Enhanced-NR.webp')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          </section>

          <section>
            <h2 style={{ color: "#ef199f" }}>Care Home Training Packages</h2>

            <a
              href="https://wecarefully.co.uk"
              target="_blank"
              rel="noreferrer"
            >
              <div id="carefully-card"></div>
            </a>
          </section>

          <section>
            <h2>Pricing</h2>

            <div
              id="pricing"
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <div className="card" style={{ aspectRatio: "16/9" }}>
                <ul>
                  <li>Clinic:</li>
                  <li>&nbsp;</li>
                  <li>£55 Physiotherapy</li>
                  <li>£45 Sports massage</li>
                  <li>
                    £80 Specialist physiotherapy (Respiratory / PMLD /
                    Neurological / Long covid)
                  </li>
                </ul>
              </div>
              <div
                className="card"
                style={{ aspectRatio: "16/9", flexDirection: "column" }}
              >
                <ul>
                  <li>Home visits:</li>
                  <li>&nbsp;</li>
                  <li>£75 Initial assessment</li>
                  <li>£60 Follow up appointment</li>
                  <li>
                    £38 Physiotherapy assistant for 30 minutes (delegated after
                    physiotherapy assessment)
                  </li>
                </ul>
                <p
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    fontSize: "0.8rem",
                  }}
                >
                  Travel within 5 miles of WR11 4SU is complimentary (subject to
                  a £1.50 per mile each way surcharge beyond this).
                </p>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Home;
